import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import ColorTopBar from 'components/ColorTopBar';
import ContactContent from 'components/ContactContent';

const ContactPage: FC<ContactTypes.ContactPageProps> = ({
  data: {
    contactPage: { urls, seo, title, content, smallText },
  },
}) => (
  <Layout headerTransparent>
    <Seo {...{ urls, seo }} />
    <ColorTopBar />
    <ContactContent title={title} content={content} smallText={smallText} />
  </Layout>
);

export const query = graphql`
  query ($url: String, $lang: String) {
    contactPage(url: { eq: $url }, lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      title
      content
      smallText
    }
  }
`;

export default ContactPage;
