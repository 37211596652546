import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';

import { ContactContentProps } from './models';

import './ContactContent.scss';

const ContactContent: FC<ContactContentProps> = ({ title, content, smallText }) => (
  <section className="contact-content">
    <h1 className="contact-content__title">{title}</h1>
    <DangerouslySetInnerHtml className="contact-content__content" html={content} />
    <DangerouslySetInnerHtml className="contact-content__small-text" html={smallText} />
  </section>
);
export default ContactContent;
